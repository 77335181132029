// BootstrapのJavaScript側の機能を読み込む
import "bootstrap";

// Scroll Hint
import ScrollHint from 'scroll-hint';

// スタイルシートを読み込む
import "./index.scss";

// Scroll Hint
new ScrollHint('.table-container', {
  suggestiveShadow: true,
  remainingTime: 5000,
  i18n: {
    scrollable: 'スクロールできます'
  }
});